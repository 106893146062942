define("discourse/plugins/discourse-topic-trade-buttons/discourse/initializers/extend-topic-for-sold-button", ["exports", "discourse/lib/plugin-api", "discourse/models/topic", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _topic, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeWithApi(api) {
    const currentUser = api.getCurrentUser();
    _topic.default.reopen(dt7948.p({
      canTopicBeMarkedAsSold: function () {
        const enable_sold_button = this.category_enable_sold_button ? this.category_enable_sold_button.toLowerCase() === "true" : false;
        return !this.isPrivatemessage && currentUser && currentUser.id === this.user_id && this.siteSettings.topic_trade_buttons_enabled && enable_sold_button && !this.get("archived");
      },
      canTopicBeMarkedAsPurchased: function () {
        const enable_purchased_button = this.category_enable_purchased_button ? this.category_enable_purchased_button.toLowerCase() === "true" : false;
        return !this.isPrivatemessage && currentUser && currentUser.id === this.user_id && this.siteSettings.topic_trade_buttons_enabled && enable_purchased_button && !this.get("archived");
      },
      canTopicBeMarkedAsExchanged: function () {
        const enable_exchanged_button = this.category_enable_exchanged_button ? this.category_enable_exchanged_button.toLowerCase() === "true" : false;
        return !this.isPrivatemessage && currentUser && currentUser.id === this.user_id && this.siteSettings.topic_trade_buttons_enabled && enable_exchanged_button && !this.get("archived");
      },
      canTopicBeMarkedAsCancelled: function () {
        const enable_cancelled_button = this.category_enable_cancelled_button ? this.category_enable_cancelled_button.toLowerCase() === "true" : false;
        return !this.isPrivatemessage && currentUser && currentUser.id === this.user_id && this.siteSettings.topic_trade_buttons_enabled && enable_cancelled_button && !this.get("archived");
      }
    }, [["field", "canTopicBeMarkedAsSold", [(0, _decorators.default)("archived", "custom_fields.enable_sold_button")]], ["field", "canTopicBeMarkedAsPurchased", [(0, _decorators.default)("archived", "custom_fields.enable_purchased_button")]], ["field", "canTopicBeMarkedAsExchanged", [(0, _decorators.default)("archived", "custom_fields.enable_exchanged_button")]], ["field", "canTopicBeMarkedAsCancelled", [(0, _decorators.default)("archived", "custom_fields.enable_cancelled_button")]]]));
  }
  var _default = _exports.default = {
    name: "extend-topic-for-sold-button",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.1", initializeWithApi);
    }
  };
});